(function($) {
    $('.gdpr-opt-out').click(function (e) {
        e.preventDefault();
        $.ajax({
            method: 'get',
            url: '/api/member/gdpr-opt-out-cookies/',
            success: function() {
                location.reload();
            }
        });
    });

    $('.gdpr-opt-in').click(function (e) {
        e.preventDefault();
        $.ajax({
            method: 'get',
            url: '/api/member/gdpr-opt-in-cookies/',
            success: function() {
                location.reload();
            }
        });
    });

    var queryStringJson = JSON.stringify(window.Edu.queryString);

    function seedRegistrationModalGdpr(data) {
      window.Edu.reactDispatch('gdprStatus',data);
      window.Edu.gdpr = data;
    }

    // Store

    /**
     * Below is the api to set tracking data for users to allow using page caching for anonymous
     */
    $.post('/api/member/status_tracking', {"query_string" : queryStringJson, 'url': window.location.pathname});


    /**
     * Below is calling the api to handle some logic for:
     * 1. Handle messageQueue
     * 2. Handle KKI referrer banner
     * 3. GDPR
     * 4. CSRF
     * 5. livechat
     */
    $.post('/api/member/status_info', {"query_string":queryStringJson, 'url': window.location.pathname}, function(resp) {
        if(resp.status) {
            if(resp.result) {
                getStatusInfo(resp);
                seedRegistrationModalGdpr(resp.result);
            }
        }
        else {
            // If the api call failed, then call the api again
            $.post('/api/member/status_info', {"query_string" : queryStringJson}, function(resp) {
                if(resp.status && resp.result) {
                  getStatusInfo(resp);
                  seedRegistrationModalGdpr(resp.result);
                }
            });
        }
    });

    function getStatusInfo(resp) {
        // If got messageStack data, show them (The api will only return messageStack for anonymous users)
        if (resp.result.messageStack) {
            var msgHTML = '<div class="messagestack" id="messagestack"><div>';
            $.each(resp.result.messageStack, function(key, msg) {
                msgHTML += '<ul class="messagestack-message ' + msg['displayType'] + '"><li>';
                msgHTML += msg['displayType'] === 'success' ? '<i class="icon-ok-circled"></i>' : msg['displayType'] === 'error' ? '<i class="icon-attention-circled"></i>' : '<i class="icon-info-circled"></i>';
                msgHTML += '<div class="message">' + msg['message'] + '</div><div class="close-message"><i class="icon-cancel-light"></i></div></li></ul>';
            });
            msgHTML += '</div></div>';

            $(msgHTML).insertBefore($('main'));
        }

        // If got KKI-banner, then show it
        if (resp.result.kkiBanner) {
            var output = '<div class="redirect-container"><a class="btn btn-link cancel" href="#"><i class="icon-cancel-light"></i></a>';
            output += '<div class="content"><img src="https://www.education.com/files/static/kki/kki-logo.png" alt="Kids Know It Logo" title="Kids Know It Logo" class="logo"><div class="text">';
            output += '<h4>Wait, where am I?</h4>';
            output += '<span class="redirected-from">' + resp.result.kkiBanner + '</span> is now a part of Education.com. We have handpicked a page similar to what you were looking for! </div>';
            output += '<div class="right"><img src="/files/static/kki/floyd_modal.png" alt="Floyd" title="Floyd" class="floyd"><img src="/files/static/kki/floyd_shadow.png" class="floyd-shadow"> <a class="btn btn-primary" href="/kids-know-it/?cid=10.9911">Learn More</a></div></div></div>';

            $(output).insertBefore($('footer'));

            $('.cancel', '.redirect-container').click(function(e) {
                e.preventDefault();
                $('.redirect-container').addClass('hidden');
            });
        }

        // If in GDPR country, show more options in sign-up form
        if(resp.status && resp.result && resp.result.inGDPRCountry ) {
            $('.modal#signup-form .registration-form .gdpr-options').show();
        }

        // If in a GDPR country and disabled cookie
        if(resp.status && resp.result && resp.result.disabledCookieInGDPR ) {
            // Show GDPR banner
            $('.gdpr-banner').show();

            // Add gdpr class to footer
            $('footer.unified').addClass('gdpr');

            // Show GDPR checkbox in signin form
            //$('.modal#signup-form .sign-in .gdpr').show();

            // Show GDPR cookie checkbox in sign up form
            //$('.modal#signup-form .registration-form .gdpr-options .gdpr').show();
        }

        // If in a GDPR country and enabled cookie
        if(resp.status && resp.result && resp.result.enabledCookieInGDPR ) {
            $('li.in-gdpr-enabled').show();
        }

        // If got ctSecret, handle it
        if(resp.status !== undefined && resp.status && resp.result.ctSecret) {
            window.Edu.csrfToken = resp.result.ctSecret;
        }

        // If livechat is enabled, append the live-chat js
        if(resp.result.liveChatEnabled && !resp.result.disabledCookieInGDPR) {
            var liveChatJS = '<!-- Start of LiveChat (www.livechatinc.com) code -->' +
                '<script type="text/javascript">window.__lc = window.__lc || {}; window.__lc.license = 7645691;(function() { var lc = document.createElement("script"); lc.type = "text/javascript"; lc.async = true; lc.src = "//cdn.livechatinc.com/tracking.js"; var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(lc, s);})();'+
                'var LC_API=LC_API||{};LC_API.on_after_load=function(){if("Edu"in window&&"memberId"in window.Edu){var custom_variables=[{name:"email",value:window.Edu.email},{name:"memberId",value:window.Edu.memberId}];LC_API.set_custom_variables(custom_variables)}LC_API.on_chat_started=function(data){document.cookie="liveChat=Date.now();domain=.education.com;path=/;";if(window.Edu&&window.Edu.trackEvent){window.Edu.trackEvent("livechat",{action:"chat started", agent:data.agent_name})}};if(window.Edu&&window.Edu.trackEvent){LC_API.on_chat_window_opened=function(){window.Edu.trackEvent("livechat",{action:"chat opened"})};LC_API.on_message=function(data){window.Edu.trackEvent("livechat",{action:"message sent from "+data.user_type})};LC_API.on_rating_submitted=function(data){window.Edu.trackEvent("livechat",{action:"chat rated "+data})}}};</script>'+
                '<!-- End of LiveChat code -->';
            $(liveChatJS).insertAfter($('footer'));
        }
    }

    $.get('/api/member/account_info', function(resp) {
        if (resp.status && resp.result) {
            updateAccountInfo(resp);
        }
    });

    function updateAccountInfo(resp) {
        var accountInfo = JSON.parse(window.localStorage.getItem('accountInfo') || '[]');
        var accountExists = false;
        var currentAccountIndex = null;

        if (accountInfo.length !== 0) {
            accountInfo.forEach(function(account, index) {
                if (resp.result.email === account.email) {
                    accountExists = true;
                    // Always update dateLastLogin to current date timestamp
                    accountInfo[index].dateLastLogin = new Date().getTime();
                    currentAccountIndex = index;
                }
            });
        }

        if (!accountExists) {
            accountInfo.push({
                'email': resp.result.email,
                'membershipType': resp.result.membershipType,
                'userType': resp.result.userType,
                'socialSignupTypes': resp.result.socialSignupTypes,
                'dateLastLogin': new Date().getTime()
            });
        } else if (currentAccountIndex !== null) {
            var keys = Object.keys(accountInfo[currentAccountIndex]);
            keys.forEach(function(key) {
                if (resp.result[key] !== accountInfo[currentAccountIndex][key]) {
                    accountInfo[currentAccountIndex][key] = resp.result[key];
                }
            });
        }

        // Sort accounts by last login date
        accountInfo.sort(function(a, b) {
          if(!a.hasOwnProperty("dateLastLogin")){
            a.dateLastLogin = 0;
          }
          if(!b.hasOwnProperty("dateLastLogin")) {
            b.dateLastLogin = 0;
          }
          return b.dateLastLogin - a.dateLastLogin;
        });

        window.localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
    }
}(window.jQuery));
